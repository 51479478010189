import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { MITPService } from "../../services/mitp.service";
import { Observable } from "rxjs";
import { MultipleUsersCreationResult } from "../../entities/users/multiple-users-creation-result";

@Injectable({
	providedIn: "root",
})
export class UsersService {
	constructor(
		private httpClient: HttpClient,
		private mitp: MITPService
	) {}

	updatePharmacyRole(userId: number, value: boolean): Observable<HttpResponse<string[]>> {
		const url = this.mitp.Users() + `/${userId}/roles/pharmacy`;
		if (value) {
			return this.httpClient.post<string[]>(url, null, {
				observe: "response",
			});
		} else {
			return this.httpClient.delete<string[]>(url, {
				observe: "response",
			});
		}
	}

	createMultipleUsers(data: FormData): Observable<MultipleUsersCreationResult> {
		return this.httpClient.post<MultipleUsersCreationResult>(this.mitp.Users() + `/import`, data);
	}
}
