import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuardService } from "../../guards/auth-guard.service";
import { UsersComponent } from "./users/users.component";
import { MatCardModule } from "@angular/material/card";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatRadioModule } from "@angular/material/radio";
import { MatTableModule } from "@angular/material/table";
import { MatSortModule } from "@angular/material/sort";
import { MatChipsModule } from "@angular/material/chips";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { CredentialsInputComponent } from "./credentials-input/credentials-input.component";
import { MatDialogModule } from "@angular/material/dialog";
import { TwoFAComponent } from "./two-fa/two-fa.component";
import { MatStepperModule } from "@angular/material/stepper";
import { QRCodeModule } from "angularx-qrcode";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { NgxMaskDirective } from "ngx-mask";
import { A11yModule } from "@angular/cdk/a11y";
import { SecureGuard } from "../../guards/secure.guard";
import { MFACodeComponent } from "./mfacode/mfacode.component";

const routes: Routes = [
	{
		path: "",
		canActivate: [AuthGuardService, SecureGuard],
		component: UsersComponent,
		pathMatch: "full",
	},
];

@NgModule({
	imports: [
		CommonModule,
		RouterModule.forChild(routes),
		MatCardModule,
		MatButtonModule,
		MatIconModule,
		FormsModule,
		MatCheckboxModule,
		MatRadioModule,
		MatTableModule,
		MatSortModule,
		MatChipsModule,
		MatMenuModule,
		MatPaginatorModule,
		MatFormFieldModule,
		MatInputModule,
		MatDialogModule,
		ReactiveFormsModule,
		MatStepperModule,
		QRCodeModule,
		MatProgressSpinnerModule,
		NgxMaskDirective,
		A11yModule,
		UsersComponent,
		CredentialsInputComponent,
		TwoFAComponent,
		MFACodeComponent,
	],
})
export class UsersModule {}
