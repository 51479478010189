<div class="flex flex-col">
	<nav data-role="ribbonmenu">
		<ul class="tabs-holder">
			<li>
				<a href="#section-one">{{ "USERS_MENU" | translate }}</a>
			</li>
		</ul>
		<div class="content-holder">
			<div
				class="section"
				id="section-one">
				<div class="group">
					<button
						class="ribbon-button"
						(click)="createUser()">
						<span class="icon">
							<mat-icon>person_add</mat-icon>
						</span>
						<span class="caption">{{ "USERS_NEW_USER" | translate }}</span>
					</button>
					<button
						class="ribbon-button"
						*ngIf="manager.isAdmin()"
						(click)="createMultipleUsers()">
						<span class="icon">
							<mat-icon>group_add</mat-icon>
						</span>
						<span class="caption">{{ "USERS_NEW_USER" | translate }}</span>
					</button>

					<button
						class="ribbon-button"
						(click)="exportExcel()">
						<span class="icon">
							<mat-icon>receipt_long</mat-icon>
						</span>
						<span class="caption">{{ "USERS_EXPORT" | translate }}</span>
					</button>

					<span class="title">{{ "USERS_USERS" | translate }}</span>
				</div>
				<div class="group">
					<button
						class="ribbon-button"
						(click)="transferManagers()"
						*ngIf="manager.isAdmin()">
						<span class="icon">
							<mat-icon>transfer_within_a_station</mat-icon>
						</span>
						<span class="caption">{{ "USERS_TRANSFER_ADMINISTRATION" | translate }}</span>
					</button>
					<button
						class="ribbon-button"
						(click)="showAuthCode()">
						<span class="icon">
							<mat-icon>pin</mat-icon>
						</span>
						<span class="caption">Codes d'inscription</span>
					</button>
					<button
						class="ribbon-button"
						(click)="transferUser()">
						<span class="icon">
							<mat-icon>badge</mat-icon>
						</span>
						<span class="caption">{{ "users.user.transfer.label" | translate }}</span>
					</button>
					<span class="title">{{ "USERS_ADMINISTRATION" | translate }}</span>
				</div>

				<div
					class="group"
					*ngIf="manager.isAdmin()">
					<button
						class="ribbon-button"
						(click)="roles()">
						<span class="icon">
							<mat-icon>people</mat-icon>
						</span>
						<span class="caption">{{ "USERS_ROLES" | translate }}</span>
					</button>
					<button
						class="ribbon-button"
						(click)="departments()">
						<span class="icon">
							<mat-icon>domain</mat-icon>
						</span>
						<span class="caption">{{ "USERS_DEPARTMENTS" | translate }}</span>
					</button>
					<button
						class="ribbon-button"
						(click)="openWifiGenerator()">
						<span class="icon"><mat-icon svgIcon="wifi-devices"></mat-icon></span>
						<span class="caption">{{ "wifi.qr.label" | translate }}</span>
					</button>
					<button
						class="ribbon-button"
						(click)="openCredentialsQrGenerator()">
						<span class="icon"><mat-icon>qr_code_2</mat-icon></span>
						<span class="caption">{{ "users.credentials.qr.label" | translate }}</span>
					</button>
					<span class="title">{{ "USERS_ANNEXES" | translate }}</span>
				</div>
				<div class="group"></div>
			</div>
		</div>
	</nav>

	<div
		class="flex flex-row"
		style="margin: 32px 0 32px 0">
		<mat-card
			appearance="outlined"
			class="w-2/3"
			style="margin: 0 16px 0 32px">
			<mat-card-title>{{ "USERS_SEARCH" | translate }}</mat-card-title>
			<mat-card-content class="flex flex-col">
				<div class="flex flex-row justify-end">
					<button
						mat-button
						(click)="clearFilters()">
						<mat-icon>clear_all</mat-icon>
					</button>
				</div>
				<mat-form-field
					appearance="outline"
					class="w-full">
					<mat-label>{{ "USERS_FILTER_BY_FIRST_AND_LAST_NAME" | translate }}</mat-label>
					<input
						matInput
						[(ngModel)]="filters.keyword"
						(ngModelChange)="filterUsers()"
						spellcheck="false" />
					<mat-icon matSuffix>search</mat-icon>
				</mat-form-field>
				<app-institute-filter
					placeholder="{{ 'USERS_FILTER_BY_ESTBLISHMENT' | translate }}"
					[(institutes)]="filters.institutes"
					(institutesChange)="filterUsers()"
					[mode]="EFilterMode.ALL_INSTITUTES"
					*ngIf="manager.isAdmin()"></app-institute-filter>
				<div class="flex flex-row justify-between">
					<mat-checkbox
						[(ngModel)]="filters.status.enabled"
						(ngModelChange)="filterUsers()"
						>{{ "USERS_ENABLED" | translate }}
					</mat-checkbox>
					<mat-checkbox
						[(ngModel)]="filters.status.pending"
						(ngModelChange)="filterUsers()"
						>{{ "USERS_PENDING" | translate }}
					</mat-checkbox>
					<mat-checkbox
						[(ngModel)]="filters.status.disabled"
						(ngModelChange)="filterUsers()"
						>{{ "USERS_DISABLED" | translate }}
					</mat-checkbox>
					<mat-checkbox
						[(ngModel)]="filters.status.deleted"
						(ngModelChange)="filterUsers()"
						>{{ "USERS_DELETED" | translate }}
					</mat-checkbox>
				</div>
				<div style="margin: 16px 0 16px 0">
					<mat-radio-group
						class="flex flex-row justify-around"
						[(ngModel)]="filters.roles"
						(ngModelChange)="filterUsers()">
						<mat-radio-button [value]="-1">{{ "USERS_ALL_USERS" | translate }}</mat-radio-button>
						<mat-radio-button [value]="1">{{ "USERS_BY_FUNCTIONS" | translate }}</mat-radio-button>
					</mat-radio-group>
				</div>
				<div
					style="margin-bottom: 32px"
					class="flex flex-row justify-between"
					*ngIf="filters.roles === 1">
					<mat-checkbox
						[(ngModel)]="filters.manager"
						(ngModelChange)="filterUsers()"
						>{{ "USERS_ADMIN_PROTOCOLS" | translate }}
					</mat-checkbox>
					<mat-checkbox
						[(ngModel)]="filters.admin_directory"
						(ngModelChange)="filterUsers()"
						>{{ "USERS_ADMIN_DIRECTORY" | translate }}
					</mat-checkbox>
					<mat-checkbox
						[(ngModel)]="filters.admin_users"
						(ngModelChange)="filterUsers()"
						>{{ "USERS_ADMIN_USERS" | translate }}
					</mat-checkbox>
				</div>
				<div></div>
				<button
					mat-fab
					color="primary"
					(click)="createUser()"
					aria-label=""
					style="position: absolute; right: 40px; bottom: -25px; z-index: 99">
					<mat-icon>add</mat-icon>
				</button>
			</mat-card-content>
		</mat-card>

		<mat-card
			appearance="outlined"
			class="w-1/3"
			style="margin: 0 32px 0 16px">
			<mat-card-title>
				<div class="flex flex-row justify-center">
					<mat-icon>multiline_chart</mat-icon>
				</div>
			</mat-card-title>
			<mat-card-content>
				<p
					*ngFor="let item of statistics"
					class="flex flex-row justify-between">
					<label>{{ item.key }} :</label>
					<label>{{ item.value }}</label>
				</p>
			</mat-card-content>
		</mat-card>
	</div>

	<div class="flex w-full flex-col">
		<table
			mat-table
			[dataSource]="dataSource"
			matSort>
			<ng-container matColumnDef="fullname">
				<th
					mat-header-cell
					*matHeaderCellDef
					mat-sort-header>
					{{ "USERS_LAST_NAME" | translate }}
				</th>
				<td
					mat-cell
					*matCellDef="let element">
					<div class="flex flex-row gap-x-2 items-center">
						<mat-icon *ngIf="element.firstname === '__GROUP__'">groups</mat-icon>
						{{ element | name }}
					</div>
				</td>
			</ng-container>
			<ng-container matColumnDef="job">
				<th
					mat-header-cell
					*matHeaderCellDef
					mat-sort-header>
					{{ "USERS_FUNCTION" | translate }}
				</th>
				<td
					mat-cell
					*matCellDef="let element">
					{{ element.job.label }}
				</td>
			</ng-container>
			<ng-container matColumnDef="institute">
				<th
					mat-header-cell
					*matHeaderCellDef
					mat-sort-header>
					{{ "USERS_ESTABLISHMENT" | translate }}
				</th>
				<td
					mat-cell
					*matCellDef="let element">
					{{ element.institute.label }}
				</td>
			</ng-container>
			<ng-container matColumnDef="roles">
				<th
					mat-header-cell
					*matHeaderCellDef
					mat-sort-header>
					{{ "USERS_PRIVILEGES" | translate }}
				</th>
				<td
					mat-cell
					*matCellDef="let element">
					<mat-chip-listbox>
						<mat-chip-option *ngFor="let role of filteredRoles(element) | async">
							{{ role }}
						</mat-chip-option>
					</mat-chip-listbox>
				</td>
			</ng-container>
			<tr
				mat-header-row
				*matHeaderRowDef="displayedColumns"></tr>
			<tr
				mat-row
				*matRowDef="let row; columns: displayedColumns"
				[matMenuTriggerFor]="menu">
				<mat-menu #menu="matMenu">
					<button
						mat-menu-item
						(click)="editUser(row)">
						<mat-icon>edit</mat-icon>
						<span>{{ "core.edit" | translate }}</span>
					</button>
					<button
						mat-menu-item
						*ngIf="manager.isAdmin() && row.totp_enabled"
						(click)="disableMFA(row)">
						<mat-icon>lock_open</mat-icon>
						<span>{{ "users.user.settings.2fa.disable.label" | translate }}</span>
					</button>
					<button
						mat-menu-item
						[matMenuTriggerFor]="subscriptionMenu"
						*ngIf="manager.isAdmin()">
						<mat-icon>euro_symbol</mat-icon>
						<span>{{ "USERS_SUBSCRIPTION" | translate }}</span>
					</button>
					<button
						mat-menu-item
						(click)="editRoles(row)"
						*ngIf="manager.isAdmin()">
						<mat-icon>account_circle</mat-icon>
						<span>{{ "USERS_PRIVILEGES" | translate }}</span>
					</button>
					<button
						mat-menu-item
						[matMenuTriggerFor]="pwdMenu">
						<mat-icon>vpn_key</mat-icon>
						<span>{{ "USERS_NEW_IDENTIFIER" | translate }}</span>
					</button>
					<button
						mat-menu-item
						(click)="disableUser(row)"
						*ngIf="manager.isAdminUsers()">
						<mat-icon>do_not_disturb_on</mat-icon>
						<span>Désactiver</span>
					</button>
					<button
						mat-menu-item
						(click)="deleteUser(row)"
						*ngIf="manager.isAdmin()">
						<mat-icon>delete</mat-icon>
						<span>{{ "core.delete" | translate }}</span>
					</button>
				</mat-menu>

				<mat-menu #pwdMenu="matMenu">
					<button
						mat-menu-item
						(click)="renewPassword(row, 'link')">
						<mat-icon>link</mat-icon>
						<span>{{ "USERS_LINK" | translate }}</span>
					</button>
					<button
						mat-menu-item
						(click)="renewPassword(row, 'password')"
						*ngIf="manager.isAdmin()">
						<mat-icon>password</mat-icon>
						<span>{{ "USERS_PASSWORD" | translate }}</span>
					</button>
				</mat-menu>

				<mat-menu #subscriptionMenu="matMenu">
					<button
						mat-menu-item
						(click)="notifyExpiredSubscription(row)">
						<mat-icon>notifications_active</mat-icon>
						<span>Notifier fin d'abonnement</span>
					</button>
				</mat-menu>
			</tr>
		</table>
		<mat-paginator
			[pageSizeOptions]="[10, 30, 100]"
			showFirstLastButtons></mat-paginator>
	</div>
</div>
