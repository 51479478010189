<div
	mat-dialog-content
	class="flex flex-col">
	<table
		mat-table
		[dataSource]="dataSource"
		matSort
		class="w-full">
		<ng-container matColumnDef="label">
			<th
				mat-header-cell
				*matHeaderCellDef>
				{{ "DEPARTMENTS_DEPARTMENT" | translate }}
			</th>
			<td
				mat-cell
				*matCellDef="let element">
				{{ element.label }}
			</td>
		</ng-container>

		<tr
			mat-header-row
			*matHeaderRowDef="displayedColumns"></tr>
		<tr
			mat-row
			*matRowDef="let row; columns: displayedColumns"></tr>
	</table>
	<mat-paginator
		[pageSizeOptions]="[10, 30, 100]"
		showFirstLastButtons></mat-paginator>
</div>
<div
	mat-dialog-actions
	class="flex flex-row justify-between">
	<button
		mat-button
		(click)="createDepartment()">
		<mat-icon>add</mat-icon>
	</button>
	<div class="flex-1"></div>
	<div class="flex flex-row">
		<button
			mat-button
			mat-dialog-close>
			{{ "core.close" | translate }}
		</button>
		<mat-spinner
			*ngIf="isLoading"
			color="primary"
			[diameter]="40"></mat-spinner>
	</div>
</div>
