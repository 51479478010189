<div mat-dialog-title>{{ "users.auth-code.title" | translate }}</div>
<p></p>
<div
	mat-dialog-content
	class="flex flex-col space-y-4">
	<div class="flex flex-col">
		<mat-checkbox
			color="primary"
			[(ngModel)]="isDateLimit"
			>{{ "users.auth-code.date-limit" | translate }}
		</mat-checkbox>
		<mat-form-field
			appearance="outline"
			*ngIf="isDateLimit">
			<mat-label>{{ "users.auth-code.active-until" | translate }}</mat-label>
			<input
				matInput
				[disabled]="true"
				[min]="today"
				[(ngModel)]="dateLimit"
				[matDatepicker]="picker" />
			<mat-hint>{{ "i18n.date.format" | translate }}</mat-hint>
			<mat-datepicker-toggle
				matIconSuffix
				[disabled]="false"
				[for]="picker"></mat-datepicker-toggle>
			<mat-datepicker
				#picker
				[disabled]="false"></mat-datepicker>
		</mat-form-field>
	</div>
	<div class="flex flex-col">
		<mat-checkbox
			color="primary"
			[(ngModel)]="isCountLimit"
			>{{ "users.auth-code.count-limit" | translate }}
		</mat-checkbox>
		<mat-form-field
			appearance="outline"
			*ngIf="isCountLimit">
			<mat-label>{{ "users.auth-code.usage-count" | translate }}</mat-label>
			<input
				[(ngModel)]="countLimit"
				matInput
				min="1"
				type="number" />
			<mat-icon matIconPrefix>pin</mat-icon>
		</mat-form-field>
	</div>
	<div>
		<mat-form-field
			*ngIf="code !== ''"
			appearance="outline"
			class="w-full">
			<mat-label>Code</mat-label>
			<input
				[(ngModel)]="code"
				[disabled]="true"
				matInput
				class="text-center"
				type="text" />
			<mat-icon matIconPrefix>dialpad</mat-icon>
			<mat-icon
				[matTooltip]="'users.auth-code.copy.tooltip' | translate"
				matIconSuffix
				[cdkCopyToClipboard]="code"
				class="cursor-pointer"
				>content_copy
			</mat-icon>
		</mat-form-field>
		<div class="flex flex-col items-center">
			<mat-spinner
				diameter="40"
				*ngIf="isLoading"></mat-spinner>
			<button
				*ngIf="!isLoading"
				class="w-full"
				mat-raised-button
				color="primary"
				(click)="createCode()">
				{{ "core.generate" | translate }}
			</button>
		</div>
	</div>
</div>
<div
	mat-dialog-actions
	[align]="'end'">
	<button
		mat-button
		mat-dialog-close>
		{{ "core.close" | translate }}
	</button>
</div>
