import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent } from "@angular/material/dialog";
import { User } from "../../../interfaces/users/user";
import { ToastrService } from "ngx-toastr";
import { Institute } from "../../../interfaces/institute";
import { Department } from "../../../interfaces/department";
import { MitpManagerService } from "../../../services/mitp-manager.service";
import { first, zip } from "rxjs";
import { UsersService } from "../users.service";
import { EFilterMode } from "../../institutes/institute-filter/efilter-mode";
import { MatButton } from "@angular/material/button";
import { DepartmentFilterComponent } from "../../departments/department-filter/department-filter.component";
import { InstituteFilterComponent } from "../../institutes/institute-filter/institute-filter.component";
import { MatRadioButton, MatRadioGroup } from "@angular/material/radio";
import { MatCheckbox } from "@angular/material/checkbox";
import { MatOption } from "@angular/material/core";
import { NgFor, NgIf } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatSelect } from "@angular/material/select";
import { MatFormField, MatLabel } from "@angular/material/form-field";
import { NgxTolgeeModule } from "@tolgee/ngx";
import { InstituteService } from "../../../services/institute.service";
import { DepartmentService } from "../../../services/department.service";
import { UserService } from "../../../services/user.service";

@Component({
	selector: "app-user-roles",
	templateUrl: "./user-roles.component.html",
	styleUrls: ["./user-roles.component.scss"],
	standalone: true,
	imports: [
		MatDialogContent,
		MatFormField,
		MatLabel,
		MatSelect,
		ReactiveFormsModule,
		FormsModule,
		NgFor,
		MatOption,
		MatCheckbox,
		NgIf,
		MatRadioGroup,
		MatRadioButton,
		InstituteFilterComponent,
		DepartmentFilterComponent,
		MatDialogActions,
		MatButton,
		MatDialogClose,
		NgxTolgeeModule,
	],
})
export class UserRolesComponent implements OnInit {
	states = [
		{ id: 0, label: "Désactivé" },
		{ id: 1, label: "Activé" },
		{ id: 2, label: "En attente de confirmation" },
		{ id: -1, label: "Supprimé" },
	];
	admin_user_institutes: Institute[] = [];
	admin_user_departments: Department[] = [];
	isManager = this.manager.isManager(this.user);
	isQuality = this.manager.isQuality(this.user);
	isCoder = this.manager.isCoder(this.user);
	isPharmacist = this.manager.isPharmacist(this.user);
	isAdminDirectory: boolean = this.manager.isAdminDirectory(this.user);
	isAdminUsers: boolean = this.manager.isAdminUsers(this.user);
	private original_user: User = { ...this.user };
	adminUsersType: string = null;

	constructor(
		@Inject(MAT_DIALOG_DATA) public user: User,
		private userService: UserService,
		private manager: MitpManagerService,
		private toastr: ToastrService,
		private usersService: UsersService,
		private departmentService: DepartmentService,
		private instituteService: InstituteService
	) {}

	ngOnInit(): void {
		const getDpts = this.departmentService.getDepartments();
		const getInstitutes = this.instituteService.getInstitutes();
		zip(getDpts, getInstitutes).subscribe(([dpts, institutes]) => {
			let nId;
			let s;
			let i;
			const matches = this.user.roles.filter((s) => s.includes("ROLE_ADMIN_USERS"));
			if (matches.length > 0) {
				if (matches.filter((s) => s.includes("ROLE_ADMIN_USERS_FULL")).length > 0) {
					this.adminUsersType = "admin-users-full";
				} else if (matches.filter((s) => s.includes("ROLE_ADMIN_USERS_INSTITUTE")).length > 0) {
					this.adminUsersType = "admin-users-institute";
					for (i = 0; i < matches.length; i++) {
						s = matches[i].split("_");
						nId = parseInt(s[4]);
						const nInstitute = institutes.find((e) => e.id == nId);
						this.admin_user_institutes.push(nInstitute);
					}
				} else if (matches.filter((s) => s.includes("ROLE_ADMIN_USERS_DEPARTMENT")).length > 0) {
					this.adminUsersType = "admin-users-department";
					for (i = 0; i < matches.length; i++) {
						s = matches[i].split("_");
						nId = parseInt(s[4]);
						const nDepartment = dpts.find((e) => e.id == nId);
						this.admin_user_departments.push(nDepartment);
					}
				}
			}
		});
	}

	changeStatus() {
		let status = "";
		switch (this.user.status) {
			case 0:
				status = "disable";
				break;
			case 1:
				status = "enable";
				break;
			case -1:
				status = "delete";
				break;
		}
		this.userService.updateUserStatus(this.user.id, status).subscribe((response) => {
			if (response.status == 204) {
				this.toastr.success("Etat du compte modifié avec succès.");
				this.original_user.status = this.user.status;
			} else {
				this.toastr.error("Une erreur inconnue est survenue !");
				this.user.status = this.original_user.status;
			}
		});
	}

	setManagerRole() {
		this.userService.setUserManager(this.user.id, this.isManager).subscribe((response) => {
			if (response.status == 200) {
				this.toastr.success("Modification effectuée avec succès.");
				this.user.roles = response.body;
			} else {
				this.toastr.error("Impossible de modifier les privilèges de cet utilisateur !");
				this.isManager = !this.isManager;
			}
		});
	}

	setAdminDirectoryRole() {
		this.userService.setUserAdminDirectory(this.user.id, this.isAdminDirectory).subscribe((response) => {
			if (response.status == 200) {
				this.toastr.success("Modification effectuée avec succès.");
				this.user.roles = response.body;
			} else {
				this.toastr.error("Impossible de modifier les privilèges de cet utilisateur !");
				this.isAdminDirectory = !this.isAdminDirectory;
			}
		});
	}

	setAdminUsersRole() {
		if (!this.isAdminUsers) {
			this.userService.setUserAdminUsers(this.user.id, this.isAdminUsers).subscribe((response) => {
				if (response.status == 200) {
					this.toastr.success("Modification effectuée avec succès.");
					this.user.roles = response.body;
				} else {
					this.toastr.error("Impossible de modifier les privilèges de cet utilisateur !");
					this.isAdminUsers = !this.isAdminUsers;
				}
			});
		}
	}

	setPharmacyRole(): void {
		this.usersService
			.updatePharmacyRole(this.user.id, this.isPharmacist)
			.pipe(first())
			.subscribe({
				next: () => {
					this.toastr.success("Modification effectuée avec succès.");
					this.user.roles = this.isPharmacist
						? [...this.user.roles, "ROLE_PHARMACY"]
						: this.user.roles.filter((role) => role !== "ROLE_PHARMACY");
				},
				error: () => {
					this.toastr.error("Impossible de modifier les privilèges de cet utilisateur !");
					this.isPharmacist = !this.isPharmacist;
				},
			});
	}

	setUserQualityRole() {
		this.userService.setUserQuality(this.user.id, this.isQuality).subscribe((response) => {
			if (response.status == 200) {
				this.toastr.success("Modification effectuée avec succès.");
				this.user.roles = response.body;
			} else {
				this.toastr.error("Impossible de modifier les privilèges de cet utilisateur !");
				this.isQuality = !this.isQuality;
			}
		});
	}

	setAdminUsersRoleType() {
		this.userService.setUserAdminUsers(this.user.id, true, this.adminUsersType).subscribe((response) => {
			if (response.status == 200) {
				this.toastr.success("Modification effectuée avec succès.");
				this.user.roles = response.body;
				this.admin_user_institutes = [];
				this.admin_user_departments = [];
			} else {
				this.toastr.error("Impossible de modifier les privilèges de cet utilisateur !");
			}
		});
	}

	addInstituteRole(institute: Institute) {
		this.userService.setUserInstituteRole(this.user.id, institute.id, true).subscribe((response) => {
			if (response.status == 204) {
				this.user.roles.push(`ROLE_ADMIN_USERS_INSTITUTE_${institute.id}`);
				this.toastr.success("Modification effectuée avec succès.");
			} else {
				const index = this.admin_user_institutes.map((e) => e.id).indexOf(institute.id);
				if (index > -1) {
					this.admin_user_institutes.splice(index, 1);
				}
				this.toastr.error("Impossible de modifier les privilèges de cet utilisateur !");
			}
		});
	}

	removeInstituteRole(institute: Institute) {
		this.userService.setUserInstituteRole(this.user.id, institute.id, false).subscribe((response) => {
			if (response.status == 204) {
				const index = this.user.roles.indexOf(`ROLE_ADMIN_USERS_INSTITUTE_${institute.id}`);
				if (index > -1) {
					this.user.roles.splice(index, 1);
				}
				this.toastr.success("Modification effectuée avec succès.");
			} else {
				this.admin_user_institutes.push(institute);
				this.toastr.error("Impossible de modifier les privilèges de cet utilisateur !");
			}
		});
	}

	addDepartmentRole(dpt: Department) {
		this.userService.setUserDepartmentRole(this.user.id, dpt.id, true).subscribe((response) => {
			if (response.status == 204) {
				this.user.roles.push(`ROLE_ADMIN_USERS_DEPARTMENT_${dpt.id}`);
				this.toastr.success("Modification effectuée avec succès.");
			} else {
				const index = this.admin_user_departments.map((e) => e.id).indexOf(dpt.id);
				if (index > -1) {
					this.admin_user_departments.splice(index, 1);
				}
				this.toastr.error("Impossible de modifier les privilèges de cet utilisateur !");
			}
		});
	}

	removeDepartmentRole(dpt: Department) {
		this.userService.setUserDepartmentRole(this.user.id, dpt.id, false).subscribe((response) => {
			if (response.status == 204) {
				const index = this.user.roles.indexOf(`ROLE_ADMIN_USERS_DEPARTMENT_${dpt.id}`);
				if (index > -1) {
					this.user.roles.splice(index, 1);
				}
				this.toastr.success("Modification effectuée avec succès.");
			} else {
				this.admin_user_departments.push(dpt);
				this.toastr.error("Impossible de modifier les privilèges de cet utilisateur !");
			}
		});
	}

	setCoderRole(): void {
		this.userService.setUserCoderRole(this.user.id, this.isCoder).subscribe((response) => {
			if (response.status == 200) {
				this.toastr.success("Modification effectuée avec succès.");
				this.user.roles = response.body;
			} else {
				this.toastr.error("Impossible de modifier les privilèges de cet utilisateur !");
				this.isCoder = !this.isCoder;
			}
		});
	}

	protected readonly EFilterMode = EFilterMode;
}
