<div mat-dialog-content>
	<div
		[formGroup]="formGroup"
		class="flex flex-col">
		<mat-form-field
			appearance="outline"
			class="w-full">
			<mat-label>{{ "auth.username.label" | translate }}</mat-label>
			<input
				matInput
				formControlName="username" />
			<mat-icon matIconPrefix>alternate_email</mat-icon>
		</mat-form-field>
		<mat-form-field
			appearance="outline"
			class="full">
			<mat-label>{{ "auth.password.label" | translate }}</mat-label>
			<input
				matInput
				[type]="hide ? 'password' : 'text'"
				formControlName="password"
				(keyup.enter)="generate()" />
			<mat-icon matIconPrefix>vpn_key</mat-icon>
			<button
				mat-icon-button
				matSuffix
				(click)="hide = !hide"
				[attr.aria-label]="'Hide password'"
				[attr.aria-pressed]="hide">
				<mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
			</button>
		</mat-form-field>
	</div>
</div>
<div
	mat-dialog-actions
	[align]="'end'">
	<button
		mat-button
		mat-dialog-close>
		{{ "core.close" | translate }}
	</button>
	<button
		mat-raised-button
		color="primary"
		(click)="generate()"
		[disabled]="!formGroup.valid">
		{{ "core.generate" | translate }}
	</button>
</div>
