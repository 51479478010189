<div mat-dialog-title>{{ "users.user.settings.2fa.title" | translate }}</div>
<div mat-dialog-content>
	<mat-stepper
		linear
		#stepper>
		<mat-step
			[label]="'users.user.settings.2fa.steps.qr-code' | translate"
			[completed]="codeValidated">
			<div class="flex flex-col items-center">
				<p>{{ "users.user.settings.2fa.instructions.qrcode" | translate }}</p>
				<qrcode
					[qrdata]="qrContent"
					[allowEmptyString]="true"
					[cssClass]="'center'"
					[colorDark]="'#000000ff'"
					[colorLight]="'#ffffffff'"
					[elementType]="'canvas'"
					[errorCorrectionLevel]="'M'"
					[imageSrc]="'./assets/images/mitp.webp'"
					[imageHeight]="75"
					[imageWidth]="75"
					[margin]="4"
					[scale]="1"
					[width]="300"></qrcode>
				<p>{{ "users.user.settings.2fa.instructions.code" | translate }}</p>
				<mat-form-field
					class="w-full"
					appearance="outline">
					<mat-label>{{ "users.user.settings.2fa.unique-code" | translate }}</mat-label>
					<input
						[(ngModel)]="mfaCode"
						matInput
						cdkTrapFocus
						cdkTrapFocusAutoCapture
						class="text-center"
						(keyup.enter)="requestNextStep(stepper)"
						mask="000 000"
						required />
				</mat-form-field>
			</div>
		</mat-step>
		<mat-step [label]="'users.user.settings.2fa.steps.backup-codes' | translate">
			<div>
				<p>
					{{ "users.user.settings.2fa.instructions.backup" | translate }}
				</p>
				<ul>
					<li
						*ngFor="let code of backupCodes"
						class="font-serif text-2xl">
						{{ code }}
					</li>
				</ul>
			</div>
		</mat-step>
		<mat-step
			[label]="'users.user.settings.2fa.steps.confirmation' | translate"
			[completed]="confirmBackupCodes">
			<p>{{ "users.user.settings.2fa.instructions.backup-confirm" | translate }}</p>
			<ul>
				<li
					*ngFor="let code of backupCodes"
					class="font-serif text-2xl">
					{{ code }}
				</li>
			</ul>
			<mat-checkbox [(ngModel)]="confirmBackupCodes">{{
				"users.user.settings.2fa.instructions.confirm" | translate
			}}</mat-checkbox>
		</mat-step>
	</mat-stepper>
</div>
<div
	mat-dialog-actions
	[align]="'end'">
	<button
		mat-button
		mat-dialog-close>
		{{ "core.cancel" | translate }}
	</button>
	<mat-spinner
		*ngIf="isLoading"
		diameter="40"></mat-spinner>
	<button
		*ngIf="stepper.selectedIndex < stepper.steps.length - 1 && !isLoading"
		(click)="requestNextStep(stepper)"
		mat-button
		color="primary"
		[disabled]="mfaCode === ''">
		{{ "core.next" | translate }}
	</button>
	<button
		*ngIf="stepper.selectedIndex === stepper.steps.length - 1 && !isLoading"
		(click)="enable2FA()"
		[disabled]="!confirmBackupCodes"
		mat-raised-button
		color="primary">
		{{ "core.enable" | translate }}
	</button>
</div>
