<div
	mat-dialog-content
	class="flex flex-col">
	<mat-form-field
		class="w-full"
		appearance="outline">
		<mat-label>{{ "TRANSFER-MANAGERS_MANAGER_CURRENT" | translate }}</mat-label>
		<input
			type="text"
			matInput
			[formControl]="m1Control"
			[matAutocomplete]="auto" />
		<mat-autocomplete
			#auto="matAutocomplete"
			[displayWith]="displayFn">
			<mat-option
				*ngFor="let option of filteredUsers | async"
				[value]="option">
				{{ option.firstname }} {{ option.lastname }}
				<i>({{ option.institute.label }})</i>
			</mat-option>
		</mat-autocomplete>
		<mat-icon matSuffix>person</mat-icon>
	</mat-form-field>
	<mat-form-field
		class="w-full"
		appearance="outline">
		<mat-label>{{ "TRANSFER-MANAGERS_NEW_MANAGER" | translate }}</mat-label>
		<input
			type="text"
			matInput
			[formControl]="m2Control"
			[matAutocomplete]="auto2" />
		<mat-autocomplete
			#auto2="matAutocomplete"
			[displayWith]="displayFn">
			<mat-option
				*ngFor="let option of filteredUsers2 | async"
				[value]="option">
				{{ option.firstname }} {{ option.lastname }}
				<i>({{ option.institute.label }})</i>
			</mat-option>
		</mat-autocomplete>
		<mat-icon matSuffix>person</mat-icon>
	</mat-form-field>
	<p>
		<mat-checkbox [(ngModel)]="removeCurrentManager">{{
			"TRANSFER-MANAGERS_REMOVE_MANAGER_CURRENT" | translate
		}}</mat-checkbox>
	</p>
</div>
<div
	mat-dialog-actions
	[align]="'end'">
	<button
		mat-button
		mat-dialog-close>
		{{ "core.close" | translate }}
	</button>
	<mat-spinner
		*ngIf="isLoading"
		color="primary"
		[diameter]="40"></mat-spinner>
	<button
		mat-raised-button
		color="primary"
		(click)="apply()"
		*ngIf="!isLoading"
		[disabled]="m1Control.value === null || m2Control.value === null">
		{{ "TRANSFER-MANAGERS_TRANSFER" | translate }}
	</button>
</div>
