import { Component } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators, ReactiveFormsModule } from "@angular/forms";
import { QrCodeService } from "../../../services/qr-code.service";
import { MatButton } from "@angular/material/button";
import { MatOption } from "@angular/material/core";
import { MatSelect } from "@angular/material/select";
import { MatIcon } from "@angular/material/icon";
import { MatInput } from "@angular/material/input";
import { MatFormField, MatLabel, MatPrefix } from "@angular/material/form-field";
import { MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from "@angular/material/dialog";
import { NgxTolgeeModule } from "@tolgee/ngx";

@Component({
	selector: "app-connect-to-wifi",
	templateUrl: "./connect-to-wifi.component.html",
	styleUrls: ["./connect-to-wifi.component.scss"],
	standalone: true,
	imports: [
		MatDialogTitle,
		MatDialogContent,
		ReactiveFormsModule,
		MatFormField,
		MatLabel,
		MatInput,
		MatIcon,
		MatPrefix,
		MatSelect,
		MatOption,
		MatDialogActions,
		MatButton,
		MatDialogClose,
		NgxTolgeeModule,
	],
})
export class ConnectToWifiComponent {
	formGroup: UntypedFormGroup;

	constructor(
		private formBuilder: UntypedFormBuilder,
		private qrCodeService: QrCodeService
	) {
		this.formGroup = this.formBuilder.group({
			ssid: ["", [Validators.required]],
			password: ["", [Validators.required]],
			type: ["", [Validators.required]],
		});
	}

	generate(): void {
		const ssid = this.formGroup.get("ssid").value;
		const password = this.formGroup.get("password").value;
		const type = this.formGroup.get("type").value;
		this.qrCodeService.generateWifiQrCode(ssid, password, type);
	}
}
