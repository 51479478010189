<div
	mat-dialog-content
	class="flex flex-col">
	<mat-form-field
		appearance="outline"
		class="w-full">
		<mat-label>{{ "USER-ROLES_STATUS_ACCOUNT" | translate }}</mat-label>
		<mat-select
			[(ngModel)]="user.status"
			(ngModelChange)="changeStatus()">
			<mat-option
				*ngFor="let item of states"
				[value]="item.id"
				>{{ item.label }}
			</mat-option>
		</mat-select>
	</mat-form-field>
	<p class="grid grid-cols-2 gap-4">
		<mat-checkbox
			[(ngModel)]="isManager"
			(ngModelChange)="setManagerRole()"
			>{{ "USER-ROLES_MANAGER_PROTOCOLS" | translate }}
		</mat-checkbox>
		<mat-checkbox
			[(ngModel)]="isAdminDirectory"
			(ngModelChange)="setAdminDirectoryRole()"
			>{{ "USER-ROLES_MANAGER_DIRECTORY" | translate }}
		</mat-checkbox>
		<mat-checkbox
			[(ngModel)]="isAdminUsers"
			(ngModelChange)="setAdminUsersRole()"
			>{{ "USER-ROLES_MANAGER_USERS" | translate }}
		</mat-checkbox>
		<mat-checkbox
			[(ngModel)]="isQuality"
			(ngModelChange)="setUserQualityRole()"
			>{{ "users.roles.quality.label" | translate }}
		</mat-checkbox>
		<mat-checkbox
			[(ngModel)]="isPharmacist"
			(ngModelChange)="setPharmacyRole()"
			>{{ "users.roles.pharmacist.label" | translate }}
		</mat-checkbox>
		<mat-checkbox
			[(ngModel)]="isCoder"
			(ngModelChange)="setCoderRole()"
			>{{ "users.roles.coder.label" | translate }}
		</mat-checkbox>
	</p>
	<div *ngIf="isAdminUsers">
		<hr />
		<div class="flex flex-col">
			<label
				><b>{{ "USER-ROLES_ADMINISTRATION_USERS" | translate }}</b></label
			>
			<mat-radio-group
				[(ngModel)]="adminUsersType"
				class="flex flex-row"
				(ngModelChange)="setAdminUsersRoleType()">
				<mat-radio-button
					value="admin-users-full"
					class="flex-1"
					>{{ "USER-ROLES_TOTAL" | translate }}
				</mat-radio-button>
				<mat-radio-button
					value="admin-users-institute"
					class="flex-1"
					>{{ "USER-ROLES_BY_ESTABLISHMENT" | translate }}
				</mat-radio-button>
				<mat-radio-button
					value="admin-users-department"
					class="flex-1"
					>{{ "USER-ROLES_BY_DEPARTMENT" | translate }}
				</mat-radio-button>
			</mat-radio-group>
		</div>
		<p style="margin-top: 8px">
			<app-institute-filter
				placeholder="{{ 'USER-ROLES_ASSIGN_ESTABLISHMENT' | translate }}"
				[(institutes)]="admin_user_institutes"
				(addInstitute)="addInstituteRole($event)"
				(removeInstitute)="removeInstituteRole($event)"
				[mode]="EFilterMode.ALL_INSTITUTES"
				*ngIf="adminUsersType === 'admin-users-institute'"></app-institute-filter>
			<app-department-filter
				placeholder="{{ 'USER-ROLES_ASSIGN_DEPARTMENT' | translate }}"
				[(departments)]="admin_user_departments"
				(addEvent)="addDepartmentRole($event)"
				(removeEvent)="removeDepartmentRole($event)"
				*ngIf="adminUsersType === 'admin-users-department'"></app-department-filter>
		</p>
	</div>
</div>
<div
	mat-dialog-actions
	[align]="'end'">
	<button
		mat-button
		mat-dialog-close>
		{{ "core.close" | translate }}
	</button>
</div>
