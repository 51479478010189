import { Injectable } from "@angular/core";
import { Job } from "../interfaces/job";
import { HttpClient } from "@angular/common/http";
import { MITPService } from "./mitp.service";

@Injectable({
	providedIn: "root",
})
export class JobService {
	constructor(
		private httpClient: HttpClient,
		private mitp: MITPService
	) {}

	getJobs() {
		return this.httpClient.get<Job[]>(this.mitp.Jobs());
	}

	createJob(label: string) {
		return this.httpClient.post<Job>(this.mitp.Jobs(), { label: label });
	}
}
