<div mat-dialog-title>{{ "wifi.connect.label" | translate }}</div>
<p></p>
<div mat-dialog-content>
	<div [formGroup]="formGroup">
		<mat-form-field
			class="w-full"
			appearance="outline">
			<mat-label>{{ "wifi.ssid.label" | translate }}</mat-label>
			<input
				formControlName="ssid"
				matInput />
			<mat-icon matIconPrefix>wifi</mat-icon>
		</mat-form-field>
		<mat-form-field
			class="w-full"
			appearance="outline">
			<mat-label>{{ "login.password" | translate }}</mat-label>
			<input
				formControlName="password"
				matInput />
			<mat-icon matIconPrefix>password</mat-icon>
		</mat-form-field>
		<mat-form-field
			class="w-full"
			appearance="outline">
			<mat-label>Chiffrement</mat-label>
			<mat-select formControlName="type">
				<mat-option [value]="'WPA'">WPA/WPA2/WPA3</mat-option>
				<mat-option [value]="'WEP'">WEP</mat-option>
				<mat-option [value]="'nopass'">{{ "core.none" | translate }}</mat-option>
			</mat-select>
			<mat-icon matIconPrefix>router</mat-icon>
		</mat-form-field>
	</div>
</div>
<div
	mat-dialog-actions
	[align]="'end'">
	<button
		mat-button
		mat-dialog-close>
		{{ "core.close" | translate }}
	</button>
	<button
		mat-raised-button
		[disabled]="formGroup.invalid"
		(click)="generate()"
		color="primary">
		{{ "core.generate" | translate }}
	</button>
</div>
