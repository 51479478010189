import { Component, ViewChild } from "@angular/core";
import { FilePond, FilePondOptions } from "filepond";
import { EFilterMode } from "../../institutes/institute-filter/efilter-mode";
import { UsersService } from "../users.service";
import { ToastrService } from "ngx-toastr";
import { Institute } from "../../../interfaces/institute";
import { finalize, first } from "rxjs";
import { MatDialogRef, MatDialogContent, MatDialogActions, MatDialogClose } from "@angular/material/dialog";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { NgIf } from "@angular/common";
import { MatButton } from "@angular/material/button";
import { InstituteFilterComponent } from "../../institutes/institute-filter/institute-filter.component";
import { FilePondModule } from "ngx-filepond";
import { NgxTolgeeModule } from "@tolgee/ngx";

@Component({
	selector: "app-create-multiple-users",
	templateUrl: "./create-multiple-users.component.html",
	styleUrls: ["./create-multiple-users.component.scss"],
	standalone: true,
	imports: [
		MatDialogContent,
		FilePondModule,
		InstituteFilterComponent,
		MatDialogActions,
		MatButton,
		MatDialogClose,
		NgIf,
		MatProgressSpinner,
		NgxTolgeeModule,
	],
})
export class CreateMultipleUsersComponent {
	label: string = "";
	isLoading: boolean = false;
	selectedInstitute: Institute = null;
	fileTypes: string[] = [
		"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
		"application/vnd.ms-excel",
		"text/csv",
	];
	@ViewChild("myPond") myPond!: FilePond;

	pondOptions: FilePondOptions = {
		allowMultiple: false,
		labelIdle: "Veuillez sélectionner le tableau contenant les comptes à créer.",
		acceptedFileTypes: this.fileTypes,
		labelFileTypeNotAllowed: "Format non autorisé",
		fileValidateTypeLabelExpectedTypes: "Fichiers autorisés : {allTypes}",
		fileValidateTypeLabelExpectedTypesMap: {
			"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "Excel",
			"application/vnd.ms-excel": "Excel",
			"text/csv": "CSV",
			credits: false,
		},
	};

	constructor(
		private usersService: UsersService,
		private toastr: ToastrService,
		private dialogRef: MatDialogRef<CreateMultipleUsersComponent>
	) {}

	isDisabled(): boolean {
		return (
			this.myPond?.getFile()?.fileType !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
			this.myPond?.getFile()?.fileType !== "application/vnd.ms-excel" &&
			this.myPond?.getFile()?.fileType !== "text/csv"
		);
	}

	create(): void {
		this.isLoading = true;
		const data = new FormData();
		data.append("file", this.myPond.getFile()?.file);
		data.append("institute", this.selectedInstitute.id.toString());
		this.usersService
			.createMultipleUsers(data)
			.pipe(
				first(),
				finalize(() => (this.isLoading = false))
			)
			.subscribe({
				next: (response) => {
					this.toastr.success("Les comptes ont été créés avec succès.");
					console.log(response);
					this.dialogRef.close();
				},
			});
	}

	protected readonly EFilterMode = EFilterMode;

	updateInstitute($event: Institute[]): void {
		if ($event.length === 1) {
			this.selectedInstitute = $event[0];
		} else {
			this.toastr.error("Vous ne pouvez sélectionner qu'un seul établissement.");
		}
	}
}
