import { Component, ElementRef, Inject, OnInit, ViewChild } from "@angular/core";
import {
	MAT_DIALOG_DATA,
	MatDialogActions,
	MatDialogClose,
	MatDialogContent,
	MatDialogRef,
	MatDialogTitle,
} from "@angular/material/dialog";
import { User } from "../../../interfaces/users/user";
import { Observable, zip } from "rxjs";
import { Institute } from "../../../interfaces/institute";
import { OrderByPipe } from "ngx-pipes";
import {
	FormsModule,
	ReactiveFormsModule,
	UntypedFormBuilder,
	UntypedFormControl,
	UntypedFormGroup,
	Validators,
} from "@angular/forms";
import { Job } from "../../../interfaces/job";
import { Department } from "../../../interfaces/department";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { map, startWith } from "rxjs/operators";
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { ToastrService } from "ngx-toastr";
import { MitpManagerService } from "../../../services/mitp-manager.service";
import { UserService } from "../../../services/user.service";
import * as fuzz from "fuzzball";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { MatButton } from "@angular/material/button";
import { MatChipGrid, MatChipInput, MatChipRemove, MatChipRow } from "@angular/material/chips";
import { MatOption } from "@angular/material/core";
import { MatSelect } from "@angular/material/select";
import { MatIcon } from "@angular/material/icon";
import { MatInput } from "@angular/material/input";
import { MatFormField, MatHint, MatLabel, MatPrefix } from "@angular/material/form-field";
import { MatCheckbox } from "@angular/material/checkbox";
import { AsyncPipe, DatePipe, NgFor, NgIf } from "@angular/common";
import { NgxTolgeeModule, TranslateService } from "@tolgee/ngx";
import { InstituteService } from "../../../services/institute.service";
import { DepartmentService } from "../../../services/department.service";
import { JobService } from "../../../services/job.service";

@Component({
	selector: "app-add-user",
	templateUrl: "./add-user.component.html",
	styleUrls: ["./add-user.component.scss"],
	standalone: true,
	imports: [
		MatDialogTitle,
		MatDialogContent,
		NgIf,
		MatCheckbox,
		ReactiveFormsModule,
		FormsModule,
		MatFormField,
		MatLabel,
		MatInput,
		MatHint,
		MatIcon,
		MatPrefix,
		MatSelect,
		NgFor,
		MatOption,
		MatChipGrid,
		MatChipRow,
		MatChipRemove,
		MatAutocompleteTrigger,
		MatChipInput,
		MatAutocomplete,
		MatDialogActions,
		MatButton,
		MatDialogClose,
		MatProgressSpinner,
		AsyncPipe,
		DatePipe,
		NgxTolgeeModule,
	],
})
export class AddUserComponent implements OnInit {
	isLoading = false;
	notifyUser = true;
	isGroup = false;
	institutes: Institute[] = [];
	jobs: Job[] = [];
	departments: Department[] = [];
	departments_all: Department[] = [];
	formGroup: UntypedFormGroup;
	dptCtrl = new UntypedFormControl();
	separatorKeysCodes: number[] = [ENTER, COMMA];
	filteredDpts: Observable<Department[]>;
	@ViewChild("dptInput") dptInput: ElementRef<HTMLInputElement>;

	constructor(
		@Inject(MAT_DIALOG_DATA) public user: User,
		private _formBuilder: UntypedFormBuilder,
		public manager: MitpManagerService,
		private userService: UserService,
		private jobService: JobService,
		private instituteService: InstituteService,
		private toastr: ToastrService,
		private oPipe: OrderByPipe,
		private translator: TranslateService,
		private departmentService: DepartmentService,
		private dialogRef: MatDialogRef<AddUserComponent>
	) {}

	ngOnInit(): void {
		this.formGroup = this._formBuilder.group({
			firstname: ["", [Validators.required, Validators.minLength(1), Validators.maxLength(255)]],
			lastname: ["", [Validators.required, Validators.minLength(1), Validators.maxLength(255)]],
			rpps: ["", [Validators.minLength(1), Validators.maxLength(255)]],
			email: ["", [Validators.required, Validators.minLength(1), Validators.maxLength(255), Validators.email]],
			job: ["", [Validators.required]],
			institute: ["", [Validators.required]],
			lastActive: ["-", []],
			smartphone: ["-", []],
		});
		if (this.user != null) {
			this.formGroup.patchValue(this.user);
			this.departments = this.user.departments;
			this.isGroup = this.user.firstname == "__GROUP__";
			if (!this.manager.isAdmin()) {
				this.formGroup.get("institute").disable();
			}
		}
		this.get();
	}

	get(): void {
		const getJobs = this.jobService.getJobs();
		const getDepartments = this.departmentService.getDepartments();
		zip(getJobs, getDepartments).subscribe(([jobs, departments]) => {
			this.jobs = this.oPipe.transform(jobs, "label");
			this.departments_all = this.oPipe.transform(departments, "label");
			this.filteredDpts = this.dptCtrl.valueChanges.pipe(
				startWith(""),
				map((value: string | null) => (value ? this._filter(value) : this.departments_all.slice()))
			);
		});
		if (this.manager.isAdmin()) {
			this.instituteService.getInstitutes().subscribe((institutes) => {
				this.institutes = this.oPipe.transform(institutes, "label");
			});
		} else {
			this.institutes.push({
				id: this.userService.getInstituteId(),
				label: this.userService.getInstituteLabel(),
			});
		}
	}

	setFirstName($event): void {
		if ($event.checked) {
			this.formGroup.patchValue({ firstname: "__GROUP__" });
		} else {
			this.formGroup.patchValue({ firstname: "" });
		}
	}

	create(): void {
		this.isLoading = true;
		const user = {
			...this.formGroup.value,
			notify: this.notifyUser,
			departments: this.departments,
		};
		this.userService.createUser(user).subscribe({
			next: (response) => {
				this.isLoading = false;
				if (response.status == 201) {
					this.toastr.success(this.translator.instant("users.user.create.success"));
					this.dialogRef.close(response.body);
				}
			},
			error: () => {
				this.isLoading = false;
			},
		});
	}

	update(): void {
		this.isLoading = true;
		const user = {
			...this.formGroup.value,
			id: this.user.id,
			notify: this.notifyUser,
			departments: this.departments,
		};
		this.userService.updateUser(user).subscribe({
			next: (response) => {
				this.isLoading = false;
				if (response.status == 204) {
					this.toastr.success(this.translator.instant("users.user.update.success"));
					this.dialogRef.close(user);
				}
			},
			error: () => {
				this.isLoading = false;
			},
		});
	}

	remove(dpt: Department): void {
		const index = this.departments.map((e) => e.id).indexOf(dpt.id);
		if (index > -1) {
			this.departments.splice(index, 1);
		}
	}

	private _filter(value: any): Department[] {
		if (typeof value === "string") {
			const filteredByLabel = this.departments_all.filter((it) => {
				return fuzz.partial_ratio(it.label.toLowerCase(), value.trim().toLowerCase()) > 75;
			});
			const availableDpts = filteredByLabel.filter((it) => {
				return !this.departments.map((e) => e.id).includes(it.id);
			});
			return this.oPipe.transform(availableDpts, "label");
		} else {
			return this.departments_all;
		}
	}

	selected($event: MatAutocompleteSelectedEvent): void {
		this.departments.push($event.option.value);
		this.dptInput.nativeElement.value = "";
		this.dptCtrl.setValue(null);
	}

	compareJobs(job1: Job, job2: Job): boolean {
		return job1.id == job2.id;
	}

	compareInstitutes(i1: Institute, i2: Institute): boolean {
		return i1.id == i2.id;
	}
}
