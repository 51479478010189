import { Component } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { FormsModule } from "@angular/forms";
import { MatDialogModule } from "@angular/material/dialog";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { NgIf } from "@angular/common";
import { MatDatepicker, MatDatepickerInput, MatDatepickerToggle } from "@angular/material/datepicker";
import { MatIcon } from "@angular/material/icon";
import { MatTooltip } from "@angular/material/tooltip";
import { CdkCopyToClipboard } from "@angular/cdk/clipboard";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { MatButton } from "@angular/material/button";
import { NgxTolgeeModule, TranslateService } from "@tolgee/ngx";
import { UserService } from "../../../services/user.service";

@Component({
	selector: "app-create-auth-code",
	standalone: true,
	templateUrl: "./create-auth-code.component.html",
	styleUrls: ["./create-auth-code.component.scss"],
	imports: [
		FormsModule,
		MatDialogModule,
		MatCheckboxModule,
		FormsModule,
		MatFormFieldModule,
		MatInputModule,
		NgIf,
		MatDatepickerInput,
		MatDatepickerToggle,
		MatDatepicker,
		MatIcon,
		MatTooltip,
		CdkCopyToClipboard,
		MatProgressSpinner,
		MatButton,
		NgxTolgeeModule,
	],
})
export class CreateAuthCodeComponent {
	isDateLimit = false;
	isCountLimit = false;
	dateLimit: Date = null;
	countLimit = 1;
	code = "";
	isLoading = false;
	today = new Date();

	constructor(
		private userService: UserService,
		private translate: TranslateService,
		private toastr: ToastrService
	) {
		this.today.setDate(this.today.getDate() + 1);
	}

	createCode(): void {
		if (this.isDateLimit && this.dateLimit == null) {
			this.toastr.warning(this.translate.instant("users.auth-code.date-limit-required"));
			return;
		}
		if (this.isCountLimit) {
			if (this.countLimit < 1) {
				this.toastr.warning(this.translate.instant("users.auth-code.count-limit-min"));
				return;
			}
		}
		const dateLimit = this.isDateLimit ? this.dateLimit : null;
		const countLimit = this.isCountLimit ? this.countLimit : null;
		this.isLoading = true;
		this.userService.createAuthCode(dateLimit, countLimit).subscribe({
			next: (authCode) => {
				this.code = authCode.code;
				this.isLoading = false;
			},
			error: () => {
				this.toastr.error(this.translate.instant("core.message.error.unknown"));
				this.isLoading = false;
			},
		});
	}
}
