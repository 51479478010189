<div
	mat-dialog-content
	class="flex flex-col">
	<mat-form-field
		class="w-full"
		appearance="outline">
		<mat-label>{{ "CREATE-DEPARTMENT_DEPARTMENT" | translate }}</mat-label>
		<input
			matInput
			[(ngModel)]="label" />
		<mat-icon matSuffix>domain</mat-icon>
	</mat-form-field>
</div>
<div
	mat-dialog-actions
	[align]="'end'">
	<button
		mat-button
		mat-dialog-close>
		{{ "core.close" | translate }}
	</button>
	<mat-spinner
		*ngIf="isLoading"
		color="primary"
		[diameter]="40"></mat-spinner>
	<button
		mat-raised-button
		color="primary"
		(click)="apply()"
		*ngIf="!isLoading"
		[disabled]="label === ''">
		{{ "core.create" | translate }}
	</button>
</div>
