<div
	mat-dialog-content
	class="flex flex-col">
	<file-pond
		#myPond
		[options]="pondOptions"></file-pond>
	<app-institute-filter
		[mode]="EFilterMode.ALL_INSTITUTES"
		(institutesChange)="updateInstitute($event)"></app-institute-filter>
</div>
<div
	mat-dialog-actions
	[align]="'end'">
	<button
		mat-button
		mat-dialog-close>
		{{ "core.close" | translate }}
	</button>
	<mat-spinner
		*ngIf="isLoading"
		color="primary"
		[diameter]="40"></mat-spinner>
	<button
		mat-raised-button
		color="primary"
		*ngIf="!isLoading"
		(click)="create()"
		[disabled]="isDisabled()">
		{{ "core.create" | translate }}
	</button>
</div>
