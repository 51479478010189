import { Component } from "@angular/core";
import {
	MatDialogActions,
	MatDialogClose,
	MatDialogContent,
	MatDialogRef,
	MatDialogTitle,
} from "@angular/material/dialog";
import { NgxTolgeeModule, TranslateService } from "@tolgee/ngx";
import { MatButton } from "@angular/material/button";
import { MatFormField, MatLabel, MatPrefix } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { FormsModule } from "@angular/forms";
import { MatIcon } from "@angular/material/icon";
import { DepartmentFilterComponent } from "../../departments/department-filter/department-filter.component";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { NgIf } from "@angular/common";
import { MitpManagerService } from "../../../services/mitp-manager.service";
import { UserService } from "../../../services/user.service";
import { Department } from "../../../interfaces/department";
import { ToastrService } from "ngx-toastr";
import { finalize, first } from "rxjs";

@Component({
	selector: "app-transfer-user",
	standalone: true,
	imports: [
		MatDialogTitle,
		MatLabel,
		MatDialogContent,
		MatDialogActions,
		NgxTolgeeModule,
		MatButton,
		MatDialogClose,
		MatFormField,
		MatInput,
		FormsModule,
		MatIcon,
		MatPrefix,
		DepartmentFilterComponent,
		MatProgressSpinner,
		NgIf,
	],
	templateUrl: "./transfer-user.component.html",
	styleUrl: "./transfer-user.component.scss",
})
export class TransferUserComponent {
	emailAddress: string = "";
	isLoading = false;
	departments: Department[] = [];

	constructor(
		public userService: UserService,
		private toastr: ToastrService,
		private translate: TranslateService,
		private dialogRef: MatDialogRef<TransferUserComponent>
	) {}

	transfer(): void {
		this.isLoading = true;
		this.userService
			.transferUser(this.emailAddress, this.departments)
			.pipe(
				first(),
				finalize(() => (this.isLoading = false))
			)
			.subscribe((user) => {
				this.toastr.success(this.translate.instant("users.user.transfer.success"));
				this.dialogRef.close(user);
			});
	}

	isEnabled(): boolean {
		if (!MitpManagerService.isEmailAddress(this.emailAddress)) {
			return false;
		}
		return this.departments.length !== 0;
	}
}
