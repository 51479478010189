<h2 mat-dialog-title>
	{{ user === null ? "Nouvel utilisateur" : "Mise à jour" }}
</h2>
<div mat-dialog-content>
	<div>
		<div
			class="flex flex-col gap-y-2"
			style="margin-bottom: 8px">
			<div
				class="flex flex-row gap-x-4"
				*ngIf="manager.isAdmin()">
				<div class="flex-1">
					{{ "users.user.profile.smartphone.title" | translate }} :
					{{ user?.smartphone }}
				</div>
				<div class="flex-1">
					{{ "users.user.profile.last-active.title" | translate }} :
					{{ user?.last_active | date: "d/M/yyyy" }}
				</div>
			</div>
			<div class="flex flex-row">
				<mat-checkbox
					[(ngModel)]="notifyUser"
					class="flex-1"
					*ngIf="manager.isAdmin()"
					>{{ "users.user.dialog.notify-user.title" | translate }}
				</mat-checkbox>
				<mat-checkbox
					[(ngModel)]="isGroup"
					[disabled]="user !== null"
					(change)="setFirstName($event)"
					class="flex-1">
					{{ "users.user.dialog.group-account.title" | translate }}
				</mat-checkbox>
			</div>
			<p
				class="italic text-red-600"
				*ngIf="isGroup">
				{{ "users.user.dialog.group-account.description" | translate }}
			</p>
		</div>
		<form
			class="flex flex-col"
			[formGroup]="formGroup">
			<div class="flex flex-row gap-x-2">
				<mat-form-field
					class="flex-1"
					*ngIf="!isGroup"
					appearance="outline">
					<mat-label>{{ "users.user.profile.firstname.title" | translate }}</mat-label>
					<input
						matInput
						formControlName="firstname"
						required
						minlength="1"
						maxlength="255" />
					<mat-hint align="end">{{ formGroup.get("firstname").value.length }} / 255</mat-hint>
					<mat-icon matIconPrefix>badge</mat-icon>
				</mat-form-field>
				<mat-form-field
					class="flex-1"
					appearance="outline">
					<mat-label>{{ isGroup ? "Groupe" : ("users.user.profile.lastname.title" | translate) }}</mat-label>
					<input
						matInput
						formControlName="lastname"
						required
						minlength="1"
						maxlength="255" />
					<mat-hint align="end">{{ formGroup.get("lastname").value.length }} / 255</mat-hint>
					<mat-icon matIconPrefix>badge</mat-icon>
				</mat-form-field>
			</div>
			<div class="flex flex-row gap-x-2">
				<mat-form-field
					class="flex-1"
					appearance="outline">
					<mat-label>{{ "users.user.profile.rpps.title" | translate }}</mat-label>
					<input
						matInput
						formControlName="rpps"
						minlength="1"
						maxlength="255" />
					<mat-icon matIconPrefix>dialpad</mat-icon>
				</mat-form-field>
				<mat-form-field
					class="flex-1"
					appearance="outline">
					<mat-label>{{ "users.user.profile.email.title" | translate }}</mat-label>
					<input
						matInput
						formControlName="email"
						required
						minlength="1"
						maxlength="255"
						[email]="true" />
					<mat-icon matIconPrefix>alternate_email</mat-icon>
				</mat-form-field>
			</div>
			<div class="flex flex-row gap-x-2">
				<mat-form-field
					class="flex-1"
					appearance="outline">
					<mat-label>{{ "job.title" | translate }}</mat-label>
					<mat-select
						formControlName="job"
						[compareWith]="compareJobs"
						required>
						<mat-option
							*ngFor="let job of jobs"
							[value]="job"
							>{{ job.label }}</mat-option
						>
					</mat-select>
					<mat-icon matIconPrefix>work</mat-icon>
				</mat-form-field>
				<mat-form-field
					class="flex-1"
					appearance="outline">
					<mat-label>{{ "institutes.institute.title.label" | translate }}</mat-label>
					<mat-select
						formControlName="institute"
						required
						[compareWith]="compareInstitutes">
						<mat-option
							*ngFor="let institute of institutes"
							[value]="institute"
							>{{ institute.label }}</mat-option
						>
					</mat-select>
					<mat-icon matIconPrefix>location_city</mat-icon>
				</mat-form-field>
			</div>
			<mat-form-field
				appearance="outline"
				class="w-full">
				<mat-label>{{ "departments.title" | translate }}</mat-label>
				<mat-chip-grid
					#chipList
					aria-label="">
					<mat-chip-row
						*ngFor="let dpt of departments"
						[removable]="true"
						(removed)="remove(dpt)">
						<div class="flex flex-row items-center">
							<label>{{ dpt.label }}</label>
							<mat-icon
								matChipRemove
								*ngIf="true"
								>cancel</mat-icon
							>
						</div>
					</mat-chip-row>
					<input
						#dptInput
						autocomplete="false"
						[formControl]="dptCtrl"
						[matAutocomplete]="auto"
						[matChipInputFor]="chipList"
						[matChipInputSeparatorKeyCodes]="separatorKeysCodes" />
				</mat-chip-grid>
				<mat-autocomplete
					#auto="matAutocomplete"
					(optionSelected)="selected($event)">
					<mat-option
						*ngFor="let dpt of filteredDpts | async"
						[value]="dpt">
						{{ dpt.label }}
					</mat-option>
				</mat-autocomplete>
			</mat-form-field>
		</form>
	</div>
</div>
<div
	mat-dialog-actions
	[align]="'end'">
	<button
		mat-button
		[mat-dialog-close]="false">
		Fermer
	</button>
	<mat-spinner
		*ngIf="isLoading"
		color="primary"
		[diameter]="40"></mat-spinner>
	<button
		mat-raised-button
		color="primary"
		(click)="create()"
		*ngIf="user === null && !isLoading"
		[disabled]="!formGroup.valid">
		{{ "core.create" | translate }}
	</button>
	<button
		mat-raised-button
		color="primary"
		(click)="update()"
		*ngIf="user !== null && !isLoading"
		[disabled]="!formGroup.valid">
		{{ "core.edit" | translate }}
	</button>
</div>
